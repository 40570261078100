import React, { useState, useEffect } from "react";
import axios from "axios";

import Wizard from "components/wizard";
import Layout from "layout";
import Loader from "components/loader";
import { t } from "intl/index";
import ThankYouForAnswering from "components/thankYouForAnswering";
import useAppStore from "stores/appStore";

const WizardContainer = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answers, setAnswers] = useState({});
  const [apiError, setApiError] = useState(false);
  const [options] = useState(props.formData.options ?? []);
  const [showThankYou, setShowThankYou] = useState(false);
  const [version, setVersion] = useState(null);

  const { tLoading, lang, questions, testMode } = useAppStore();

  const url = new URL(window.location);
  const hash = url.searchParams.get("hash");
  const surveyId = url.searchParams.get("surveyId");

  // Fetch version on mount
  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_FASTIFY_API_URL}/v1/version`
        );
        setVersion(data.version);
        console.log("Fetched version:", data.version);
      } catch (error) {
        console.error(`Error fetching version: ${error.message}`);
      }
    };

    fetchVersion();
  }, []);

  if (tLoading) {
    return <Loader title={t(lang).LOADING || "Loading..."} />;
  }

  // Function to update the survey status if we're in test mode
  const setTestSurveyCompleted = async () => {
    if (surveyId && version) {
      try {
        // updatedData can be adjusted to use "completed" if that is your intent
        const updatedData = {
          status: "inactive", // or "completed" depending on your requirement
        };

        const response = await axios.put(
          `${process.env.REACT_APP_FASTIFY_API_URL}/${version}/surveys/${surveyId}`,
          updatedData,
          {
            headers: {
              // Pass the access token if your route requires authorization
              Authorization: `Bearer ${props.participantAccessToken}`,
            },
          }
        );

        console.log("Test survey updated successfully:", response.data);
      } catch (error) {
        console.error("Error updating test survey:", error);
      }
    } else {
      console.error("Survey ID or version is not available, cannot update test survey.");
    }
  };

  const handleNext = (values) => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questions[activeStep].id]: values,
    }));

    if (activeStep === questions.length - 1) {
      submit({
        ...answers,
        [questions[activeStep].id]: values,
      });
    }
  };

  const submit = async (finalAnswers) => {
    if (!version) {
      console.error("Version is not available, cannot submit data.");
      return;
    }

    setIsSubmitting(true);
    const { email, participantAccessToken } = props;

    if (surveyId) {
      const transformedAnswers = Object.entries(finalAnswers)
        .map(([questionId, values]) =>
          values.map((value) => ({
            survey_id: surveyId,
            from_id: hash,
            to_id: value.value,
            question_id: questionId,
          }))
        )
        .flat();

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_FASTIFY_API_URL}/${version}/aona-edges`,
          transformedAnswers,
          {
            headers: { Authorization: `Bearer ${participantAccessToken}` },
          }
        );

        console.log("Submit response:", res);
        // If in test mode, update the survey status after successful submission
        if (testMode) {
          await setTestSurveyCompleted();
        }
        setTimeout(() => {
          setShowThankYou(true);
        }, 500);
      } catch (err) {
        console.error("Error submitting survey:", err);
        props.onFinish();
      } finally {
        setIsSubmitting(false);
        setShowThankYou(true);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  if (showThankYou) {
    return <ThankYouForAnswering />;
  }

  if (isSubmitting) {
    return <Loader title={t(lang).SENDING_DATA || "Sending answer..."} />;
  }

  if (activeStep === questions.length) {
    return null;
  }

  return (
    <Layout settings={props.settings}>
      <Wizard
        onNextClick={handleNext}
        activeStep={activeStep}
        questions={questions}
        options={options}
        onBackClick={handleBack}
        settings={props.settings}
        prevAnswers={answers[questions[activeStep].id]}
      />
    </Layout>
  );
};

export default WizardContainer;
