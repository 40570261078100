import React, { useState, useEffect } from "react";
import styles from "layout/styles";
import { styled } from '@material-ui/core/styles';
import useAppStore from "stores/appStore";

const Layout = ({ settings, withoutHeader = false, children }) => {
  const { setIsMobile, getIsMobile } = useAppStore();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <styles.Container>
      {!withoutHeader && (
        <styles.Header
          primaryColor={settings?.primaryColor}
          secondaryColor={settings?.secondaryColor}
        >
          <styles.LogoContainer>
            <styles.Logo src={settings?.logoBase64} alt="logo" />
          </styles.LogoContainer>
        </styles.Header>
      )}

      <styles.Body>{children}</styles.Body>
    </styles.Container>
  );
};

export default Layout;
