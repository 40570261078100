import { create } from 'zustand';

const useAppStore = create((set, get) => ({
    testMode: false,
    accessToken: '',
    setAccessToken: (newAccessToken) => set({ accessToken: newAccessToken }),
    setTestMode: (newTestMode) => set({ testMode: newTestMode }),
    
    questions: [],
    originalQuestions: [],
    setOriginalQuestions: (newQuestions) => {
        console.log("Setting original questions:", newQuestions);
        set({ originalQuestions: newQuestions, questions: newQuestions });
    },

    isMobile: window.innerWidth <= 768,
    setIsMobile: (newIsMobile) => set({ isMobile: newIsMobile }),
    getIsMobile: () => get().isMobile,
}));

export default useAppStore;
